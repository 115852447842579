


@keyframes DownAnimation {
    from {
        opacity: 0;
        transform: translateY(78px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}  

@keyframes UpAnimation {
    from {
        opacity: 0;
        transform: translateY(-78px);
    }
    
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}  
.animation-Up {
    
    
    animation: UpAnimation 0.3s  ;

  }
.animation-down {
    
    
    animation: DownAnimation 2s  ;

  }
  .btn-next-ui{
    position: fixed; 
    border-top: 0.01px solid rgba(0, 0, 0, 0.236);
    bottom: 90px;

    padding: 15px 0px;
    z-index: 90px;
    background-color: white;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.rtl .btn-next-ui{
    right: 400px;
    left: 0px;
}
.ltr .btn-next-ui{
    right: 0px;
    left: 400px;
}

  @media screen and (max-width: 950px) {
    .btn-next-ui{
        position: fixed; 
        bottom: 0px;
        right: 0px;
        left: 0px;
        padding: 15px 0px;
        background-color: white;
        display: flex; 
        justify-content: center;
        align-items: center;
    }
    .rtl .btn-next-ui{
        right: 0px;
        left: 0px;
    }
    .ltr .btn-next-ui{
        right: 0px;
        left: 0px;
    }
  }