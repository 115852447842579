// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
// @import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
@import "./fonts.scss";

// 
*{
    color: '#000';
}

// scroll style
*::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
  }
  
  *::-webkit-scrollbar-track {
    background: #fff;        /* color of the tracking area */
  }
  
  
  *::-webkit-scrollbar-thumb {
    background-color: #C6C6C6;
    // background-image:linear-gradient( #486DF6,#1836a4);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
 /* creates padding around scroll thumb */
  }