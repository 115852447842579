/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap');

@font-face {
    font-family:'FontArabic';
    src: 
    local('FontArabic'),
         url('./Fonts/font-arabic.otf') format('otf');
} */
@import url("https://fonts.googleapis.com/css?family=Changa&display=swap");

/* .body{
    font-family: 
    'FontArabic'
    /* 'Cairo', sans-serif; */
/* }  */
html * {
  font-family: "FontArabic";
}
* {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
}

/* .csidder{
    color: #87B9C0;
    background-color: #87B9C0
} */
.csidder:hover {
  background-color: white;
}
.csidder:focus {
  background-color: white;
}

.cardBorder {
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 25px;
}
.rage-style-pc {
  background: linear-gradient(
    270deg,
    #f8e33f 0.17%,
    #ffa397 26.07%,
    #00c5fb 38.94%,
    #87a0ff 75.96%,
    #0a6ce1 100%
  );
  background-color: red;
  width: 100%;
  height: 4px;
  display: flex;
  /* flex-direction: row-reverse; */
}

.btn-normal {
  width: 200px;
  /* background: #000; */
}

.iconSurveyIntro {
  filter: invert(100%) sepia(0%) saturate(16%) hue-rotate(339deg)
    brightness(106%) contrast(103%);
}
.survey_section {
  background-color: rgba(33, 43, 167, 0.8);
}
.section-withHover:hover {
  background-color: rgba(33, 43, 167, 1);
}
.pd-section {
  padding: 20px;
}
.survey_section .icon-section {
  background-color: rgba(0, 6, 75, 0.4);
}
.rtl #switch-questions {
  right: 0px;
}
.ltr #switch-questions {
  left: 0px;
}
@media screen and (max-width: 950px) {
  .btn-normal {
    /* flex:1; */
    width: 80%;
  }

  .pd-section {
    padding: 20px;
    padding: 23px;
  }
  .iconSurveyIntro {
    filter: "invert(0)";
  }

  .survey_section:nth-child(0) {
    background-color: #141c7acc;
  }
  .survey_section:nth-child(0) .check-complete {
    background-color: #141c7acc;
  }

  .survey_section:nth-child(1) {
    background-color: #486df6;
  }
  .survey_section:nth-child(1) .check-complete {
    background-color: #486df6;
  }
  .survey_section:nth-child(2) {
    background-color: #00c0f4;
  }
  .survey_section:nth-child(2) .check-complete {
    background-color: #00c0f4;
  }

  .survey_section:nth-child(3) {
    background-color: #00e2e2;
  }
  .survey_section:nth-child(3) .check-complete {
    background-color: #00e2e2;
  }
  .survey_section:nth-child(4) {
    background-color: #708fff;
  }
  .survey_section:nth-child(4) .check-complete {
    background-color: #708fff;
  }
}

.scrollHidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollHidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.icon-MultipleChoose {
  filter: invert(63%) sepia(64%) saturate(3816%) hue-rotate(154deg)
    brightness(94%) contrast(101%);
}
#circle-center-down {
  width: 400px;
  border-radius: 400px;
  position: absolute;
  bottom: -190px;
  background-color: #fff59c;
  height: 400px;
}
.shadow-click {
  background: linear-gradient(180deg, #536fff 0%, #536fff 100%);
  box-shadow: 0px 15px 27px -14px rgba(83, 111, 255, 0.2);
}

.bg-degree {
  background: linear-gradient(
    -90deg,
    #00ff00 0%,
    #39ff00 19.06%,
    #aaff00 39.9%,
    #e3ff00 58.85%,
    #ffaa00 75.52%,
    #ff3900 89.06%,
    #ff0000 100%
  );
}
.page-A4 {
  height: 844px;
  width: 596px;
  background-color: #fff;
  border-radius: 5px;
  flex-shrink: 0;
}
.noColors {
  -webkit-filter: grayscale(100%);

  filter: grayscale(100%);
}
.c-sidebar-nav-item .c-active {
  color: #fff !important;
}

.h-100 {
  @apply h-full;
}

.c-header {
  position: fixed !important;
}

.c-main {
  padding-top: 108px !important;
}

.table-pagination ul {
  @apply gap-[6px] mb-0;
}

.table-pagination ul li {
  @apply rounded-lg border shadow-sm w-[38px] h-[38px] overflow-hidden;
}

.table-pagination ul a {
  @apply text-[#323232] w-[38px] h-[38px] border-none rounded-lg  flex items-center  justify-center text-[12px];
}

.table-pagination ul a.disabled {
  @apply bg-[#F6F9FA];
}

.table-pagination ul li.active a {
  /* @apply !bg-white !border-[#E7EEF6] !text-theme-h1; */
  @apply !bg-theme-primary !text-white;
}

.table-pagination-arrows {
  @apply text-[20px];
}

.notifications {
  @apply relative text-[20px] text-black hover:text-black w-[47px] h-[47px]  border-2 border-[#E8F0F8] rounded-full flex items-center justify-center focus:outline-none;
  margin-inline-end: 55px;
}

.notifications-icon {
  @apply absolute w-[16px] h-[16px] bg-[#F54A7D] text-[13px] text-white rounded-full flex items-center justify-center;
  top: -6px;
  inset-inline-start: -2px;
}

.notifications-bell {
  @apply text-[24px] ;
}
.custom-card {
  @apply bg-white rounded-2xl w-full border border-[#E0ECF9];
}

.custom-card-header {
  @apply w-full border-b border-[#E0ECF9] p-[18px] flex items-center justify-between;
}
.custom-card-title {
  @apply text-[20px] text-black font-medium;
}

.custom-card-body {
  @apply py-[34px] px-[24px];
}
.card {
  @apply bg-white  border !border-[#E0ECF9] !rounded-2xl overflow-hidden;
}

.card-header {
  @apply !p-[16px] border-b !border-[#E0ECF9] !text-[20px];
}

.card-title {
  @apply text-[20px] text-black font-medium;
}

.card-body {
  @apply py-[34px] px-[24px];
}

.card-text {
  @apply text-[15px] text-[#9CA3AF] font-medium;
}

/* dropdown */

.dropdown {
  z-index: 110;
  @apply !absolute bg-white shadow-md rounded-lg duration-500 group-hover:flex group-hover:flex-col group-hover:items-start;
}

.dropdown__start {
  inset-inline-start: 0;
}

.dropdown__end {
  inset-inline-end: 0;
}

.dropdown__wrapper {
  @apply relative;
}

.dropdown__wrapper--show {
  @apply flex flex-col items-start;
}

.dropdown__wrapper-item {
  @apply w-full;
}

.dropdown__wrapper-link {
  @apply block w-full py-[3.5px] px-[14px] text-sm text-theme-h1 hover:text-theme-primary  hover:bg-theme-primary text-theme-primary/25 rounded-lg duration-500 text-start;
}
.dropdown__wrapper-icon {
  @apply rotate-180;
}

.dropdown__button {
  @apply text-[#7B8292] hover:text-theme-primary  duration-500 text-[18px];
}

.dropdown__page {
  @apply h-9 text-sm py-[7px] px-[14px] text-theme-h1 flex items-center hover:text-theme-primary  hover:bg-theme-primary  text-theme-primary/25 gap-[7px] capitalize rounded-lg cursor-pointer duration-500 font-semibold;
}

.dropdown-effect {
  @apply invisible translate-y-4 opacity-0 group-hover:visible group-hover:translate-y-0 group-hover:opacity-100;
}

.dropdown-effect--show {
  @apply translate-y-0 opacity-100 visible;
}

.dropdown-list {
  @apply p-2 rounded-lg min-w-[104px] !m-0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}

.dropdown-list .dropdown-item {
  @apply w-full font-semibold text-[12px] flex items-center justify-center gap-1;
}

.dropdown-list .dropdown-item span {
  @apply text-[14px];
}

.dropdown-list .dropdown-item-chat {
  @apply py-[6px] justify-start w-[132px] text-[14px] text-theme-h1;
}

.dropdown-list .dropdown-item-chat span {
  @apply text-[20px];
}

.master-tag {
  @apply bg-yellow-400/40 text-yellow-600 text-[11px] flex items-center justify-center gap-[4px] rounded-lg h-[20px] w-[100px] font-normal;
}

.master-tag-pif {
  @apply bg-white
}



.surveyHeaderRight {
  @apply right-0 lg:right-[230px] left-0;
}

.surveyHeaderLeft {
  @apply left-0 lg:left-[230px] right-0
}
