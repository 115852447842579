// Variable overrides
// $primary:                    #1E4672;
// $secondary:                 #1E4672;
@import url('https://fonts.googleapis.com/css?family=Changa&display=swap');


@font-face {
    font-family: 'FontArabic';
    src: url("../Fonts/arabic/DIN Next LT W23 Regular.otf");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'FontArabic';
    src: url("../Fonts/arabic/DIN NEXT™ ARABIC MEDIUM .otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FontArabic';
    src: url("../Fonts/arabic/DIN Next LT W23 Bold.otf");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'FontEnglish';
    src: url("../Fonts/english/AirbnbCerealMedium.ttf") ;
         font-weight: 100;
         font-style: normal;
}
@font-face {
    font-family: 'FontEnglish';
    src:url("../Fonts/english/AirbnbCerealLight.ttf") ;
         font-weight: normal;
         font-style: normal;
}
@font-face {
    font-family: 'FontEnglish';
    src:url("../Fonts/english/AirbnbCerealBold.ttf") ;
         font-weight: bold;
         font-style: normal;
}



// Color
$white:                     #ffffff;
$gray:                     #9C9C9C;

$primary:                   #2B2E82;
$secondary:                 #1E4672 ;
$success:                   #e2e24c;
$warning:                   #0f0e0d;
$info:                      #17a2b8;
$danger:                    #e43f52;
$dark:                      #3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f8f9fc;

// // Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d; 
$gray-700:                  #495057;
// // Overlay
$overlay:                   #1E4672;
$gradident-overlay:         #2B2E82;
// // Body Background
$body-color:                #161c2d;
$shadow:                    1px 1px 10px rgba(0,0,0,.15);
// // Base font


:lang(ar) 	{
    $font-family-base:           'FontArabic'!important;
    $font-family-secondary:      'FontArabic' !important;
    $font-size:            18px;
    }

:lang(en) 	{
    $font-family-base:           'FontEnglish'!important;
    $font-family-secondary:      'FontEnglish' !important;
    $font-size:            18px;
    }


$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "light": $light,
    "gray": $gray,
)