.react-datepicker-wrapper input {
  border: none !important;
  outline: none !important;
  font-size: 14px;
  color: #8b8b8b;
  width: 90px !important;
  border: 1px solid #e0ecf9 !important;
  padding: 9px 14px !important;
  border-radius: 9px;
}

.react-datepicker__tab-loop {
  width: 90px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation {
  margin-top: 15px;
  border: none !important;
  outline: none !important;
  width: 18px !important;
}

.react-datepicker__navigation--next {
  margin-right: 15px;
}

.react-datepicker__navigation--previous {
  margin-left: 15px;
}

.react-datepicker {
  width: 300px;
  height: 285px;
  /* border: 1px solid #e4e6e9 !important; */
  /* box-shadow: 0 5px 14px 0 #0000001a !important; */
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  overflow-y: hidden;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  padding: 0 !important;
  align-items: center !important;
}

.react-datepicker__header {
  background-color: #fff !important;
  border: 0 !important;
  padding-top: 18px !important;
}

.react-datepicker__navigation-icon {
  top: 0 !important ;
  /* display: none !important; */
}

.react-datepicker__navigation--next {
  left: 35px !important;
  right: unset !important;
}

.react-datepicker__navigation--previous {
  margin-left: 0px !important;
}

[dir="ltr"] .react-datepicker__navigation--next {
  left: unset !important;
  right: 0px !important;
  margin-right: 0px !important;
}

[dir="ltr"] .react-datepicker__navigation--previous {
  margin-left: 0px !important;
  left: unset !important;
  right: 35px !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #000 !important;
  border-width: 2px 2px 0 0 !important;
}

.react-datepicker__month-wrapper {
  display: flex;
  justify-content: center;
  gap: 23px;
}

.react-datepicker__month-text {
  width: 73px !important;
  height: 26px !important;
  border-radius: 5px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.react-datepicker__month-text--selected {
  background-color: #486df6 !important;
  color: #fff !important;
}

.react-datepicker__week {
  display: flex;
  /* justify-content: ; */
  flex-direction: row;
}

[dir="rtl"] .react-datepicker__week {
  flex-direction: row-reverse;
}

.react-datepicker__day {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  position: relative;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

[dir="rtl"] .react-datepicker__day-names {
  flex-direction: row-reverse;
}

.dropdown-icon {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  inset-inline-end: 8px;
  color: #acb5ca;
}

.react-datepicker-popper {
  position: unset !important;
  transform: translate(0px, 0px) !important;
}

.react-datepicker__current-month {
  text-align: start;
  color: #051b44;
  margin-bottom: 16px;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color: rgb(230 237 255 / var(--tw-bg-opacity));
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  border-radius: 0px !important;
  background-color: rgb(230 237 255 / var(--tw-bg-opacity));
  color: #051b44 !important;
}

.react-datepicker__day--selected {
  @apply !bg-theme-primary;
  border-radius: 50% !important;
}

.react-datepicker__day--range-end {
  @apply !bg-theme-primary;
  color: #fff !important;
  border-radius: 50% !important;
  position: relative;
}

.react-datepicker__day--in-range.react-datepicker__day--range-end {
  color: #fff !important;
}
.react-datepicker__day--range-start {
  @apply !bg-theme-primary;
  color: #fff !important;
  border-radius: 50% !important;
  position: relative;
}

.react-datepicker__day--range-end::before,
.react-datepicker__day--range-start::before,
.react-datepicker__day--selecting-range-start:before,
.react-datepicker__day--selecting-range-end:before {
  border-radius: 50% !important;
  width: 100%;
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1 !important;
  @apply !bg-theme-primary;
}

.react-datepicker__day--range-start::after,
.react-datepicker__day--selecting-range-start:after {
  width: 50%;
  content: "";
  height: 100%;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 0 !important;
  /* background-color: rgb(230 237 255 / var(--tw-bg-opacity)); */
  @apply bg-theme-primary/20;
}

.react-datepicker__day--range-end::after,
.react-datepicker__day--selecting-range-end:after {
  width: 50%;
  content: "";
  height: 100%;
  position: absolute;
  right: 50%;
  top: 0;
  z-index: 0 !important;
  @apply bg-theme-primary/20;
}

.day {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.react-datepicker__day--keyboard-selected {
  background: none !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--selecting-range-end,
    .react-datepicker__day--selecting-range-start
      .react-datepicker__day--selecting-range-start
      .react-datepicker__day--selecting-range-end
  ) {
  @apply !bg-theme-primary/20;
}

.react-datepicker__day--in-range.react-datepicker__day--in-range {
  @apply !bg-theme-primary/20;
}

.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selecting-range-start {
  @apply !bg-theme-primary;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker__day--range-end.react-datepicker__day--range-start::after,
.react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-start::after {
  background-color: #fff !important;
}

.calendar {
  @apply !w-[405px] !h-[250px];
}

.calendar .react-datepicker__month-container {
  @apply overflow-hidden;
}

.calendar .react-datepicker__month-wrapper {
  @apply justify-evenly w-full mb-[15px];
}

.calendar .react-datepicker__month-text {
  @apply !w-[95px] !h-[36px] border border-[#E4E6E966] rounded-[9px];
}

/* .calendar .react-datepicker__month-text--selected {
  @apply !bg-white !border-theme-primary !text-theme-primary;
} */

.calendar .react-datepicker__month-text--keyboard-selected {
  @apply !bg-white !border-theme-primary !text-theme-primary;
}

.calendar .react-datepicker__month-text--selected {
  @apply !bg-white !border-theme-primary !text-theme-primary;
}

.calendar .react-datepicker__navigation--next {
  left: unset !important;
  right: 20px !important;
  margin-right: 0 !important;
}

.calendar .react-datepicker__navigation--previous {
  left: 20px !important;
}
